import React, { useEffect } from "react";
import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

import 'styles/pages/dynamicsforms.css';

export default function DynamicsFormPage2() {
    useEffect(loadDynamics, []);
    function loadDynamics() {
      const script = document.createElement('script');
      script.src = "https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/oce/FormLoader/FormLoader.bundle.js";
      script.async = true;
      document.body.insertBefore(script, document.body.childNodes[0]);      
    }

    const formId = process.env.CRM_TEST_FORM_ID;
    const formDataUrl = `https://public-oce.mkt.dynamics.com/api/v1.0/orgs/${process.env.CRM_TEST_FORM_API_ID}/landingpageforms`;
    const cachedFormUrl = `https://assets-oce.mkt.dynamics.com/${process.env.CRM_TEST_FORM_API_ID}/digitalassets/forms/${formId}`;
  
    return (
      <>
          <SEO title="WA Senior Card members save big with Kleenheat" 
            	 description="WA Seniors Card members can enjoy bigger savings on natural gas, thanks to our exciting local partnership."
        	/>
            <Header />
            <LayoutMain>
              <LayoutPanel background="mer-bg--ui-light mer-border--bottom mer-border--color-gs-lighter"
                      padding="mer-pt-lg">
                <div className="container">

                  <div        
                    id="dynamicsforms"
                    data-form-id={formId}
                    data-form-api-url={formDataUrl}
                    data-cached-form-url={cachedFormUrl}
                  />

                </div>
              </LayoutPanel>
            </LayoutMain>
            <Footer />
		  </>      
    );
  }